body {
  /* background-image: url("./images/14.png"); */
  background-repeat: repeat-x;
  /* Make it white if you need */
  padding: 0 24px;
  margin: 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 15px;
}

.App {
  color: #72a24d;
}

img {
  width: 100%;
  max-width: 60vh;
  margin-top: 20px;
}

.Container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-top: 0px;
}

.Number {
  font-size: 9vh;
  font-weight: bold;
  color: #ffffff;
}

.Number2 {
  font-size: 9vh;
  font-weight: bold;
  color: #00ffc9;
}

.CounterTitle {
  margin-top: 2vh;
  color: white;
  font-size: 3vh;
  font-weight: bold;
}

.CounterNote {
  color: #ffe34f;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: 1.5vh;
  font-size: 2.3vh;
}

.ConterHolder {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  max-width: 60vh;
  height: 25vh;
  background-color: #10423a;
  flex-direction: column;
}

.Counter {
  margin-top: 2.3vh;
}

.btn-join-now {
  margin-top: 20px;
  max-width: 60vh;
  height: 40px;
  background: #10423a;
  box-shadow: 2px 2px #888888;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
  font-size: 23px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
  text-decoration: none !important;
}

.underline {
  text-decoration: none;
}
